import Container from '@/components/atoms/Container'
import {seoSocialPropTypes} from '@/functions/getPagePropTypes'
import Link from 'next/link'
import PropTypes from 'prop-types'
import styles from './Footer.module.css'
import Logo from '@/components/atoms/Logo'
import {IconContext} from 'react-icons'
import {
  TiSocialFacebookCircular,
  TiSocialInstagramCircular,
  TiSocialLinkedinCircular,
  TiSocialTwitterCircular,
  TiSocialYoutubeCircular
} from 'react-icons/ti'

/**
 * Render the Footer component.
 *
 * @author                           WebDevStudios
 * @param  {object}  props           The component attributes as props.
 * @param  {object}  props.social    Yoast SEO social media data.
 * @param  {object}  props.menu      Arrary of menu items.
 * @param  {string}  props.siteTitle Yoast SEO site title.
 * @return {Element}                 The Footer component.
 */
export default function Footer({social, siteTitle, menu}) {
  return (
    <footer className={styles.footer}>
      <Container paddingTop={false} paddingBtm={false}>
        <div className="flex flex-wrap py-4">
          <div className="basis-4/4 md:basis-2/4 mb-4 md:mb-0">
            <Link href="/">
              <a className={styles.logoWrapper} aria-label="click to go home">
                <Logo className={styles.logo} type="light" />
              </a>
            </Link>
            {!!menu && (
              <nav className={styles.footerMenu}>
                <ul>
                  {menu.map((item, index) => {
                    return (
                      <li key={index}>
                        <Link href={item.path}>
                          <a target={item.target ? item.target : '_self'}>
                            {item.label}
                          </a>
                        </Link>
                      </li>
                    )
                  })}
                </ul>
              </nav>
            )}
          </div>
          <div className="basis-4/4 md:basis-2/4">
            <div className={styles.socialmedia}>
              {!!social &&
                Object.entries(social).map(([key, value]) => {
                  if (value) {
                    return (
                      <div key={key + Date.now().toString()}>
                        {' '}
                        {/* &middot;{' '} */}
                        <Link href={value}>
                          <a>
                            <IconContext.Provider
                              value={{
                                color: 'white',
                                size: '1em',
                                className: 'socialicon'
                              }}
                            >
                              {key == 'facebook' && (
                                <TiSocialFacebookCircular />
                              )}
                              {key == 'linkedIn' && (
                                <TiSocialLinkedinCircular />
                              )}
                              {key == 'youTube' && <TiSocialYoutubeCircular />}
                              {key == 'twitter' && <TiSocialTwitterCircular />}
                              {key == 'instagram' && (
                                <TiSocialInstagramCircular />
                              )}
                            </IconContext.Provider>
                          </a>
                        </Link>
                      </div>
                    )
                  }
                })}
            </div>

            <div className={styles.copyright}>
              &copy; {new Date().getFullYear()} {siteTitle}{' '}
              {' | Developed by Skyzen Technologies'}
              {/* {!!social &&
                Object.entries(social).map(([key, value]) => {
                  if (value) {
                    return (
                      <span key={key}>
                        {' '}
                        &middot;{' '}
                        <Link href={value}>
                          <a>{key}</a>
                        </Link>
                      </span>
                    )
                  }
                })} */}
            </div>
          </div>
        </div>
      </Container>
      <a
        className={styles.whatsapp}
        href="https://api.whatsapp.com/send?phone=97430055953"
        target="_blank"
        rel="noreferrer"
      >
        <img src="/images/icons8-whatsapp.svg" alt="whatsapp" />
      </a>
    </footer>
  )
}

Footer.propTypes = {
  ...seoSocialPropTypes,
  siteTitle: PropTypes.string
}
