import isLinkActive from '@/functions/isLinkActive'
import {Popover, Transition} from '@headlessui/react'
import cn from 'classnames'
import {useSession} from 'next-auth/react'
import Image from 'next/image'
import Link from 'next/link'
import {useRouter} from 'next/router'
import PropTypes from 'prop-types'
import {Fragment, useState} from 'react'
import {usePopper} from 'react-popper'
import styles from './Navigation.module.css'

/**
 * Render the Sub menu arrow component.
 *
 * @author WebDevStudios
 * @return {object} The Arrow component.
 */
function Arrow() {
  return (
    <span
      role="button"
      className={styles.arrow}
      aria-label="expand the dropdown menu"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="10"
        viewBox="0 0 24 24"
      >
        <path d="M6 0l12 12-12 12z" />
      </svg>
    </span>
  )
}

/**
 * Render the NavigationMenu component.
 *
 * Recursively displays a menu and its children.
 *
 * @author WebDevStudios
 * @param  {object}  props      NavigationMenu props.
 * @param  {Array}   props.menu Array of menu items.
 * @return {Element}            The NavigationMenu component.
 */
function NavigationMenu({menu}) {
  const {asPath} = useRouter() ?? {}
  const {data: session, status} = useSession()
  const loading = status === 'loading'
  const isGuest = !loading && !session?.user?.accessToken

  if (!menu || !menu?.length) {
    return null
  }

  return (
    <>
      {menu.map((item, index) => {
        // Check for session-specific menu items.
        if ((loading || isGuest) && item.path === '/profile') {
          return
        } else if ((loading || !isGuest) && item.path === '/login') {
          return
        }

        const children =
          item.children && item.children.length > 0 ? item.children : ''

        return (
          <li
            key={index}
            className={cn(
              children && children?.length ? styles.hasChildren : ''
            )}
          >
            <Link href={item.path}>
              <a
                target={item.target ? item.target : '_self'}
                className={cn(isLinkActive(asPath, item.path) && styles.active)}
              >
                {item.label}
                {children && children?.length && <Arrow />}
              </a>
            </Link>

            {!!children && !!children.length && (
              <ul
                className={cn(styles.subMenu, styles[`subMenu${index + 1}`])}
                id={index}
              >
                <NavigationMenu menu={children} />
              </ul>
            )}
          </li>
        )
      })}
    </>
  )
}

NavigationMenu.propTypes = {
  menu: PropTypes.arrayOf(PropTypes.object)
}

/**
 * Render the Navigation component.
 *
 * @author                           WebDevStudios
 * @param  {object}  props           Navigation props.
 * @param  {Array}   props.menu      Array of menu items.
 * @param  {string}  props.className Optional classname for the element.
 * @return {Element}                 The Navigation component.
 */
export default function Navigation({menu, className}) {
  return (
    <>
      {!!menu?.length && (
        <nav className={cn(styles.navigation, className)}>
          <ul className={styles.menu}>
            <NavigationMenu menu={menu} />
          </ul>
        </nav>
      )}
    </>
  )
}

Navigation.propTypes = {
  className: PropTypes.string,
  menu: PropTypes.arrayOf(PropTypes.object)
}

/**
 * Render the NavigationMenuPopup component.
 *
 * Recursively displays a popover menu and its children using HeadlessUI Popup.
 *
 * @author RKC
 * @param  {object}  props      NavigationMenu props.
 * @param  {Array}   props.menu Array of menu items.
 * @return {Element}            The NavigationMenu component.
 */
function NavigationMenuPopover({menu}) {
  const {asPath} = useRouter() ?? {}
  const {data: session, status} = useSession()
  const loading = status === 'loading'
  const isGuest = !loading && !session?.user?.accessToken
  const [referenceElement, setReferenceElement] = useState(null)
  const [popperElement, setPopperElement] = useState(null)
  const {styles, attributes} = usePopper(referenceElement, popperElement)

  if (!menu || !menu?.length) {
    return null
  }

  return (
    <>
      {menu.map((menuitem, index) => {
        // Check for session-specific menu items.
        if ((loading || isGuest) && menuitem.path === '/profile') {
          return
        } else if ((loading || !isGuest) && menuitem.path === '/login') {
          return
        }
        const children =
          menuitem.children && menuitem.children.length > 0
            ? menuitem.children
            : ''

        return (
          <li
            key={index.toString()}
            className={cn(
              children && children?.length ? styles.hasChildren : ''
            )}
          >
            <Popover className="relative hidden lg:block">
              {({open}) => (
                <>
                  <Popover.Button
                    ref={setReferenceElement}
                    className={`
                      ${open ? '' : 'text-opacity-90'}
                      ${cn(
                        isLinkActive(asPath, menuitem.path) && styles.active
                      )}
                      group uppercase text-gray-800 px-3 py-2 rounded-md flex items-center text-sm font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                  >
                    {/* <span>{item.label}</span> */}
                    {!!menuitem.menuMeta && !!menuitem.menuMeta.menuIcon && (
                      <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 text-white sm:h-12 sm:w-12">
                        <Image
                          alt=""
                          width="36"
                          height="36"
                          src={menuitem.menuMeta.menuIcon.sourceUrl}
                        />
                      </div>
                    )}
                    <div className="ml-2 text-left">
                      <p className="text-xs font-medium text-gray-800">
                        {menuitem.label}
                      </p>
                      <p className="text-xs text-gray-400">{menuitem.title}</p>
                    </div>
                    {children && children?.length && <Arrow />}
                  </Popover.Button>
                  {!!children && !!children.length && (
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel
                        ref={setPopperElement}
                        style={styles.popper}
                        {...attributes.popper}
                        className={`
                          ${cn(styles.subMenu, styles[`subMenu${index + 1}`])}
                          absolute z-10 w-screen max-w-sm px-4 mt-3 transform -translate-x-1/2 left-1/2 sm:px-0 lg:max-w-3xl
                        `}
                      >
                        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                          <ul className="rounded-t-lg relative grid gap-8 bg-white p-7 lg:grid-cols-2">
                            {children.map((item, index2) => {
                              const subchildren =
                                item.children && item.children.length > 0
                                  ? item.children
                                  : ''
                              return (
                                <li
                                  key={
                                    index2.toString() + Date.now().toString()
                                  }
                                  className={cn(
                                    subchildren && subchildren?.length
                                      ? styles.hasChildren
                                      : ''
                                  )}
                                >
                                  <Link href={item.path}>
                                    <a
                                      target={
                                        item.target ? item.target : '_self'
                                      }
                                      className={`
                                      ${cn(
                                        subchildren && subchildren?.length
                                          ? styles.hasChildren
                                          : ''
                                      )}
                                      flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50
                                      `}
                                    >
                                      {!!item.menuMeta &&
                                        !!item.menuMeta.menuIcon && (
                                          <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 text-white sm:h-12 sm:w-12">
                                            <Image
                                              alt=""
                                              width="42"
                                              height="42"
                                              src={
                                                item.menuMeta.menuIcon.sourceUrl
                                              }
                                            />
                                          </div>
                                        )}
                                      <div className="ml-4">
                                        <p className="text-sm font-medium text-gray-900">
                                          {item.label}
                                        </p>
                                        <p className="text-sm text-gray-500">
                                          {item.title}
                                        </p>
                                      </div>
                                      {subchildren && subchildren?.length && (
                                        <Arrow />
                                      )}
                                    </a>
                                  </Link>
                                  {!!subchildren && !!subchildren.length && (
                                    <ul
                                      className={cn(
                                        styles.subMenu,
                                        styles[`subMenu${index2 + 1}`]
                                      )}
                                      id={index2}
                                    >
                                      <NavigationMenu menu={subchildren} />
                                    </ul>
                                  )}
                                </li>
                              )
                            })}
                          </ul>
                          <div className="px-10 py-5 bg-gray-100 rounded-b-lg">
                            <p className="flex items-center">
                              <span className="text-sm font-medium text-gray-900">
                                {menuitem.label} {menuitem.title}
                              </span>
                            </p>
                            <p className="block text-sm text-gray-500">
                              {' '}
                              {menuitem.description}{' '}
                            </p>
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  )}
                </>
              )}
            </Popover>
          </li>
        )
      })}
    </>
  )
}

NavigationMenuPopover.propTypes = {
  menu: PropTypes.arrayOf(PropTypes.object)
}

/**
 * Render the NavigationPopover component.
 *
 * @author RKC
 * @param  {object}  props           Navigation props.
 * @param  {Array}   props.menu      Array of menu items.
 * @param  {string}  props.className Optional classname for the element.
 * @return {Element}                 The Navigation component.
 */
export function NavigationPopover({menu, className}) {
  // eslint-disable-next-line no-debugger
  // debugger
  return (
    <>
      {!!menu?.length && (
        <nav className={cn(styles.navigation, className)}>
          <ul className={styles.menu}>
            <NavigationMenuPopover menu={menu} />
          </ul>
        </nav>
      )}
    </>
  )
}

NavigationPopover.propTypes = {
  className: PropTypes.string,
  menu: PropTypes.arrayOf(PropTypes.object)
}

/**
 * Render the NavigationMobile component.
 *
 * @author RKC
 * @param  {object}   props          Navigation props.
 * @param  {Array}    props.menu     Array of menu items.
 * @param  {Function} props.onToggle Toggle switch for menu button.
 * @return {Element}                 The Navigation component.
 */
export function NavigationMobile({menu, onToggle}) {
  const [heading, setHeading] = useState('')
  const [subHeading, setSubHeading] = useState('')
  if (!menu || !menu?.length) {
    return null
  }
  return (
    <>
      {menu.map((item, index) => {
        const children =
          item.children && item.children.length > 0 ? item.children : ''

        return (
          <div key={index}>
            <div className="px-3 text-left md:cursor-pointer group">
              <h1
                className="py-4 flex justify-between items-center lg:pr-0 pr-5 group"
                onClick={() => {
                  heading !== item.label
                    ? setHeading(item.label)
                    : setHeading('')
                  setSubHeading('')
                }}
              >
                <div className="flex items-center">
                  {!!item.menuMeta && !!item.menuMeta.menuIcon && (
                    <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 text-white sm:h-12 sm:w-12">
                      <Image
                        alt=""
                        width="36"
                        height="36"
                        src={item.menuMeta.menuIcon.sourceUrl}
                      />
                    </div>
                  )}
                  <div className="ml-2 text-left">
                    <p className="text-sm font-medium text-gray-800">
                      {item.label}
                    </p>
                    <p className="text-sm text-gray-400">{item.title}</p>
                  </div>
                </div>
                <span className="text-xl lg:hidden inline">
                  <ion-icon
                    name={`${
                      heading === item.label ? 'chevron-up' : 'chevron-down'
                    }`}
                  ></ion-icon>
                </span>
                <span className="text-xl lg:mt-1 lg:ml-2  lg:block hidden group-hover:rotate-180 group-hover:-mt-2">
                  <ion-icon name="chevron-down"></ion-icon>
                </span>
              </h1>
            </div>
            <div
              className={`
                ${heading === item.label ? 'lg:hidden' : 'hidden'}
              `}
            >
              {/* sublinks */}
              {children.map((item, index2) => {
                // const subchildren =
                //   item.children && item.children.length > 0 ? item.children : ''
                return (
                  <div key={index2.toString()}>
                    <Link href={item.path}>
                      <a
                        target={item.target ? item.target : '_self'}
                        onClick={onToggle}
                      >
                        <h1
                          onClick={() =>
                            subHeading !== item.label
                              ? setSubHeading(item.label)
                              : setSubHeading('')
                          }
                          className="py-4 pl-14 font-semibold lg:pr-0 pr-5 flex justify-between items-center md:pr-0"
                        >
                          <div className="ml-2 text-left">
                            <p className="text-sm font-medium text-gray-800">
                              {item.label}
                            </p>
                            <p className="text-sm text-gray-400 font-normal">
                              {item.title}
                            </p>
                          </div>

                          {/* <span className="text-xl lg:mt-1 md:ml-2 inline">
                            <ion-icon
                              name={`${
                                subHeading === item.label
                                  ? 'chevron-up'
                                  : 'chevron-down'
                              }`}
                            ></ion-icon>
                          </span> */}
                        </h1>
                        {/* <div
                          className={`${
                            subHeading === item.label ? 'lg:hidden' : 'hidden'
                          }`}
                        > */}
                        {/* {slinks.sublink.map((slink) => (
                            <li className="py-3 pl-14">
                              <Link to={slink.link}>{slink.name}</Link>
                            </li>
                          ))} */}
                        {/* </div> */}
                      </a>
                    </Link>
                  </div>
                )
              })}
            </div>
          </div>
        )
      })}
    </>
  )
}

NavigationMobile.propTypes = {
  onToggle: PropTypes.func,
  menu: PropTypes.arrayOf(PropTypes.object)
}
