import Container from '@/components/atoms/Container'
import Logo from '@/components/atoms/Logo'
import Navigation, {NavigationPopover} from '@/components/molecules/Navigation'
import {NavigationMobile} from '@/components/molecules/Navigation/Navigation'
import Link from 'next/link'
import PropTypes from 'prop-types'
import styles from './Header.module.css'
import {useState} from 'react'

/**
 * Render the Header component.
 *
 * @author WebDevStudios
 * @param  {object}  props         The component attributes as props.
 * @param  {object}  props.menuTop The header menu object.
 * @param  {object}  props.menu    The header menu object.
 * @param  {Element} props.search  The search component.
 * @return {Element}               The Header component.
 */
export default function Header({menuTop, menu, search}) {
  const [open, setOpen] = useState(false)
  // eslint-disable-next-line func-style
  const toggleMenuBtn = () => setOpen((open) => !open)

  return (
    <>
      <a className={styles.skip} href="#page-content">
        Skip to Main Content
      </a>
      <header className={styles.header}>
        <div className={styles.top}>
          <div className={styles.top_wrapper}>
            <Navigation menu={menuTop} styles={styles} />
            {search && <div className={styles.search}>{search}</div>}
          </div>
        </div>
        <Container paddingTop={false} paddingBtm={false} className="px-4">
          {/* {search && <div className={styles.search}>{search}</div>} */}
          <div className={styles.navigation}>
            <Link href="/">
              <a className={styles.logoWrapper} aria-label="click to go home">
                <Logo className={styles.logo} type="dark" />
              </a>
            </Link>
            <NavigationPopover menu={menu} styles={styles} />
            <div
              className="text-3xl lg:hidden my-auto mx-0"
              onClick={() => setOpen(!open)}
            >
              <ion-icon name={`${open ? 'close' : 'menu'}`}></ion-icon>
            </div>
          </div>
        </Container>
        <ul
          className={`
          lg:hidden bg-white fixed w-full top-24 overflow-y-auto bottom-0 py-4 pl-4
          duration-500 ${open ? 'left-0' : 'left-[-100%]'}
          `}
        >
          <NavigationMobile menu={menu} onToggle={toggleMenuBtn} />
        </ul>
      </header>
    </>
  )
}

Header.propTypes = {
  menu: PropTypes.array,
  search: PropTypes.element
}
