import Head from 'next/head'
import parse from 'html-react-parser'
import PropTypes from 'prop-types'

/**
 * Render extra meta tags in the document head.
 *
 * @author WebDevStudios
 * @param  {object}  props     The component attributes as props.
 * @param  {object}  props.seo Yoast SEO data from WordPress.
 * @return {Element}           The Meta component.
 */
export default function Meta({seo}) {
  // Combine robots data.
  const robots = [
    ...(seo?.metaRobotsNofollow ? [seo.metaRobotsNofollow] : []),
    ...(seo?.metaRobotsNoindex ? [seo.metaRobotsNoindex] : [])
  ]

  return (
    <Head>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta httpEquiv="x-ua-compatible" content="ie=edge" />
      <meta name="robots" content={robots.join(', ')} />
      <title>{seo?.title}</title>
      {seo?.fullHead ? parse(seo.fullHead) : null}
      <meta name="msapplication-TileColor" content="#fffff" />
      <meta
        name="msapplication-config"
        content="/favicon_si/browserconfig.xml"
      />
      <meta name="theme-color" content="#fff" />
      <meta
        name="facebook-domain-verification"
        content="2lxj3p2v2jli61n3dbwbh96x8l7moh"
      />
      <meta name="msvalidate.01" content="47CB7142DEF870409E56D589C0F47943" />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/favicon_si/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon_si/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon_si/favicon-16x16.png"
      />
      <link rel="manifest" href="/favicon_si/site.webmanifest" />
      <link rel="shortcut icon" href="/favicon_si/favicon.ico" />
      <link
        rel="mask-icon"
        href="/favicon_si/safari-pinned-tab.svg"
        color="#39393c"
      />
      <meta name="msapplication-TileColor" content="#da532c" />
      <meta name="theme-color" content="#ffffff"></meta>
    </Head>
  )
}

Meta.propTypes = {
  seo: PropTypes.object.isRequired
}
