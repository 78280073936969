import PropTypes from 'prop-types'
import React from 'react'

/**
 * Render the Logo component.
 *
 * @author WebDevStudios
 * @param  {object}  props           Logo props.
 * @param  {string}  props.className Optional classname for the element.
 * @param  {Array}   props.type      Type or style of the logo.
 * @return {Element}                 The Logo component.
 */
// eslint-disable-next-line no-unused-vars
export default function Logo({className, type}) {
  // prettier-ignore
  const imgsrc = type === 'light' ? '/images/sharq-logo-inverse.svg' : '/images/sharq-logo.svg'
  return <img className={className} src={imgsrc} alt="next" />
}

Logo.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(['light', 'dark']).isRequired
}

Logo.defaultProps = {
  type: 'dark'
}
